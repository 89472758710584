.outerline{
    padding: 10px;
    margin: 11px 0px;
    border: 1px solid #d9d1d1;
    box-shadow: none;
  }
 .br-clr {
    border-right: 1px solid #5b4e4e;
    
  }
  .compValue{
    color: #a19d9d;
font-size: 1rem;
  }
.bg-colorissue{
  color: rgb(146, 208, 80) !important;
}
.font-listview{
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}
.issuesumbitted{
  color: #61a6eb !important;
font-weight: 600 !important;
}
.outerline:hover{
  box-shadow: 0px 1px 4px #1976d2;
border: 1px solid #1976d2;
cursor: pointer;
}
.issuesumbittedPublish{
  color: #61a6eb !important;
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}
.compValuepublish{
  color: #a19d9d;
/* font-size: 1rem !important; */
}
.statustoggleclose{
    border: 3px solid #8c8282;
    position: relative;
    width: 24px;
    border-radius: 3px;
    right: -2px;
    left:19rem;
    justify-content: right;
    cursor: pointer;
}
.statustoggleinfo{
  position: absolute;
    width: 24px;
    border-radius: 3px;
    right: 16px;
    justify-content: right; 
    padding-top: 15px;
    padding-bottom: 68px;
    margin: 0px 1px;
    cursor: pointer;
}
.mr-20{
  margin-right:25px;
}
.backwardicon{
  position: relative;
left: 5px;
}
.openvalue{
  width: 96%;
  max-width: 96% !important;
}
.togglebtn{
  margin-top: 55px !important;
  align-self: start !important;
}

.toggleicon{
  position: relative;
  top: 3px;
  right: 0px;
}

.teamtoggle{
  position: absolute;
  right: 30px;
  border: 3px solid #8c8282;
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
}
.teamtoggleinfo{
  position: relative;
  left: 3px;
  top: 4px;
  border-radius: 5px;
  margin: 5px 20px;
  cursor: pointer;
}
.statustoggle{
  position: relative;
  top: 280px;  
}

.openvaluestatus{
  width: 95%;
  max-width: 95% !important;
}

@media screen and (max-width: 800px) {
  .br-clr {
   border: none;
  }
  .shareIconmb{
    position: absolute;
  right: 18px;
  top: 5px
  }
  .position-relative{
   position: relative;
  }
  .mr-20{
    margin-right: 0px;
  }
  .titlebb{
  border-bottom: 1px solid #e1e0e0;
  padding-bottom: 5px;
  }
  .pagespace{
    float: inline-end;
    padding-bottom: 10px;
  }
}

.f-weight-bold{
  font-weight: bold;
}