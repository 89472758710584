.mb-10{
    margin-bottom: 10px;
}
.css-q8hpuo-MuiFormControl-root{
margin-bottom: 25px;
}
.m-0{
    margin: 0;
}
.m-t-10{
  margin-top: 10px;
}
.backwardicon{
    position: relative;
  left: 5px;
  }
.header_title{
 
margin-bottom: 13px;
font-size: 1.6rem;
font-weight: 600;
color: #1976d2;
padding-top: 5px;
margin: 0px;
  }
  .pub{
    margin-bottom: 13px;
    font-size: 1rem;
    font-weight: 600;
    color: #1976d2;
    padding-top: 5px;
    margin: 0px;
  }
  .header_subtitle{
    margin: 0;
    margin-bottom: 0px;
    margin-bottom: 13px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1976d2
    }
  .mb-20{
      margin-bottom:20px
  }
  .m-b-0{
      margin-bottom:0px
  }
  .mt-7r{
    margin-top: 4.7em;
  }
  .p-10 {
    padding-top: 10px;
}
.css-1ay2c7g-MuiGrid-root>.MuiGrid-item{
    padding-top: 10px !important   
}


.issue_teamseleted{
    margin-top: 14px;
    font-size: 1.5rem;
}
.mt-10{
    margin-top: 15px;
}

.btn-issueali{
    position: absolute;
right: 0;
top: 15px;
}
/* .position-absolute{
position:absolute;
} */
.pt-10{
    padding-top: 20px;
}

.backwardicon{
    position: relative;
  left: 5px;
  }
  .openvaluesurvey{
    width: 95%;
    max-width: 95% !important;
  }
  .togglebtn{
    margin-top: 55px !important;
    align-self: start !important;
  }
  .surveytoggle{
    position: relative;
    top: 220px;
  }
  .p-l12{
    padding-left: 20px;
  }
  .publishbtn{
    position: absolute;
    right: 23px;
    margin-top: 62px;
  }
  .p-b-14{
    padding-bottom: 15px;
  }
  .publishbtnri{
    position: absolute;
    right: 25px;
    padding-top: 10px;
  }
  .issuesurveywidth{
    width: "260px"
  }
  .publishbtnbottom{
    display: none !important;
  }
  .pt-30{
    padding-top: 30px;
  }
  .header_subtitlepublish{
    font-size: 1rem;
    font-weight: 600;
    color: #1976d2 !important;
  }
  .publishtable {
    border-radius: 0px !important;
    border-radius: 0px;
    border: 1px solid #cdcdcd;
  }
  .publishtable tr th{
    padding: 2px 5px !important;
    color: #312c2c;
    background: #cdcdcd;
    font-size: 1rem;
  }
  .publishtable tr td{
    padding: 2px 5px !important
   }
   .publishbtnTop{
    display: inline-flex !important;
  }
   .publishbtnmv{
    display: none !important;
}
    @media screen and (max-width: 800px) {
      .header_title{     
        margin: 0px !important;
        font-size: 1.3rem !important;
        font-weight: 600;
        color: #1976d2;
        padding-top: 5px;
        padding-bottom: 10px;
      }
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 5.5px 14px !important;
      }
      .MuiInputBase-input-MuiOutlinedInput-input{
        padding: 4.5px 14px !important;
      }
      .MuiButtonBase-root-MuiIconButton-root{
        padding: 4px !important;
      }
      .issuesurveywidth{
        width: 185px;
      }
      .surveytoggle{
        display: none;
      }
      .mt-7r{
        margin-top: 4.2rem;
      }
      .p-l12{
        padding-left: 0px;
      }
      .header_subtitle{
        font-size: 1.3rem;
      }
   
      .openvaluesurvey{
        width: 100%;
        max-width: 100% !important;
      }
      .publishbtnTop{
       justify-content: start !important;
      }
      .publishbtnbottom{
        display: block !important;
      }
      .pb-10 {
        padding-bottom: 10px;
      }
      .pt-30 {
        padding-top: 0px !important;
      }
      .publishbtnTop{
        display: none !important;
      }
      .publishbtnmv{
        display: block !important;
    }
    }

    .cut-text{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .scrl * div{
      background-color: #ffffff !important;
      border: 1px solid #bdbdbd !important;
    }
