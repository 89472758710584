
.MainCaption
{
    BORDER-RIGHT: #6f8bdf;
    BORDER-TOP: #6f8bdf;
    BORDER-LEFT: #6f8bdf;
    COLOR: #6b6363;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Frame1
{
    BORDER-RIGHT: #c5c1c1 1px solid;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 1px solid;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #c5c1c1 1px solid;
    COLOR: #6b6363;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #c5c1c1 1px solid;
    FONT-FAMILY: Arial Rounded MT Bold, Verdana
}
.Frame2
{
    BORDER-RIGHT: #6f8bdf 2px;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 4px double;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #6f8bdf 2px;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #6f8bdf;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Head1
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 25px;
    COLOR: #6b6363;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Bullet
{
    FONT-SIZE: 10pt;
    COLOR: black;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Head2
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 14pt;
    COLOR: #6b6363;
    BORDER-BOTTOM: #c5c1c1 1px solid;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Head3
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 17pt;
    COLOR: #6b6363;
    FONT-FAMILY: Arial Rounded MT Bold
}
.Statement1
{
    FONT-SIZE: small;
    FONT-FAMILY: Arial Rounded MT Bold, Verdana
}
.Frame3
{
    BORDER-RIGHT: #c5c1c1 8px double;
    BORDER-TOP: #c5c1c1 8px double;
    BORDER-LEFT: #c5c1c1 8px double;
    BORDER-BOTTOM: #c5c1c1 8px double;
    FONT-FAMILY: Arial Rounded MT Bold
}
.ChartSplitTop
{
    BORDER-RIGHT: gray 1px solid;
    BORDER-BOTTOM: gray 1px solid
}
.ChartSplitBottom
{
    BORDER-TOP: gray 1px solid;
    BORDER-LEFT: gray 1px solid
}
.statement2
{
    FONT-SIZE: x-small;
    FONT-FAMILY: Arial Rounded MT Bold, Verdana
}
.max-4
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(../../../Images/red-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-1
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(../../../Images/blue-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-2
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(../../../Images/green-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-3
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(../../../Images/yellow-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-front-page
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 0px -70px;
    width:780px;
    height: 1400px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Cover.jpg); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-front-page2
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 0px 150px;
    width:800px;
    height: 950px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/C4D_Spiral_Colour2.jpg); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-why-page
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 0px 0px;
    width:780px;
    height: 950px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Steps-1-4.jpg); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-Plain
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 550px 0px;
    right:-50px;
    width:780px;    
    height: 950px;
    BACKGROUND-REPEAT: no-repeat
}
.YP-Red-Right
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 550px 220px;
    width:780px;
    height: 970px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Red-Right.jpg); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-Africa
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: -10px -50px;
    border:0px; 
    width:780px;
    height: 970px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Africa.jpg); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-Discuss
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 550px 680px;
    border:0px; 
    width:780px;
    height: 970px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Discuss.png); */
    BACKGROUND-REPEAT: no-repeat
}

.YP-Diversify
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: -10px -10px;
    border:0px; 
    width:780px;
    height: 970px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Green.png); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-red-banner
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 0px 500px;
    width:780px;
    height: 970px;
    /* BACKGROUND-IMAGE: url(../../../Images/Profile/Youth/YP-Develop.png); */
    BACKGROUND-REPEAT: no-repeat
}
.YP-Blue
{
    color:#3299CC;
}    
.YP-Green
{
    color:#349937;
}    
.YP-Yellow
{
    color:#FFCC03;
}    
.YP-Red
{
    color:#E73C1E;
}    
.YP-Red-Cell
{
     padding:5px; 
    color:white;
}    
.YP-Grey
{   
    color:White;
    background:#777776;
}    