.ml-10{
    margin-left: 10px;
}

.font-weight-600{
    font-weight: 600;
}

.pd-10px{
    padding: 10px;
}