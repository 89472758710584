.text-warning{
    color:red;
  }
  .txt-blueclr{
    color: rgb(54 153 255);
  }
  .bg-blueopacity{
   background-color: rgb(222 235 247);
  }
  .fw-600{
    font-weight: 600;
  }
  .marginlf-30{
margin-left: 30px !important;
  }