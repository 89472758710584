
A
{
    COLOR: #3089cb
}
A:hover
{
    COLOR: #3089cb
}

div.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
INPUT
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 9pt;
    /* BACKGROUND-IMAGE: url(../images/InputBackground.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #082e63;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: white;
    padding-left:5px;
    padding-right:5px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px
}
TEXTAREA
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 10pt;
    /* BACKGROUND-IMAGE: url(../images/InputBackground.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #082e63;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: white;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px
}
.C4D-BackGround
{
    /* BACKGROUND-IMAGE: url(../images/C4D_Spiral_Black.jpg); */
    BACKGROUND-REPEAT: no-repeat;
    background-attachment:fixed; 
    BACKGROUND-POSITION: center;
    BACKGROUND-COLOR:white
}
.C4D-Menu A
{
    FONT-WEIGHT: bolder;
    COLOR: #3089cb
}
.C4D-Menu A:hover
{
    FONT-WEIGHT: bolder;
    COLOR: #3089cb
}
.C4D-caption-blue A
{
    FONT-WEIGHT: bolder;
    COLOR: white
}
.C4D-caption-blue A:hover
{
    FONT-WEIGHT: bolder;
    COLOR: white
}
.C4D-caption-blue
{
    BACKGROUND-POSITION: left center;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #3089cb
}
.C4D-caption-yellow
{
    BACKGROUND-POSITION: left center;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #ffd900
}
.C4D-caption-green
{
    BACKGROUND-POSITION: left center;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #19b14c
}
.C4D-caption-red
{
    BACKGROUND-POSITION: left center;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #ed1b24
}
.C4D-caption-black
{
    BACKGROUND-POSITION: left center;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #231f20
}
.employeecaption
{
    BORDER-RIGHT: #a6a495 5px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\corner.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.C4D-Frame
{
    BACKGROUND-REPEAT: no-repeat;
    COLOR: #a6a495;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    BACKGROUND-COLOR: white
}

.listcaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.selectedcaption
{
    BACKGROUND-POSITION: left center;
    PADDING-LEFT: 15px;
    PADDING-RIGHT: 5px;
    PADDING-TOP: 3px;
    PADDING-BOTTOM: 3px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 15px;
    /* BACKGROUND-IMAGE: url(..\Images\arrow.gif); */
    COLOR: white;
    BACKGROUND-REPEAT: no-repeat;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: #3089cb
}
.textfields
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: darkblue
}
.textquestion
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: darkblue
}
.textlabels
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: black
}
.employeecaption A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    TEXT-DECORATION: none
}
.employeecaption A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    TEXT-DECORATION: none
}
.employeewindow
{
    BACKGROUND-COLOR: 
}
.employeeframe
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-COLOR: white
}
.employeedetails
{
    BORDER-RIGHT: medium none;
    BORDER-TOP: #a6a495 2px solid;
    BORDER-LEFT: medium none;
    BORDER-BOTTOM: medium none
}
.searchwindow
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    MARGIN-TOP: 3px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 11px;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid
}
.searchdetails
{
    BORDER-RIGHT: medium none;
    BORDER-TOP: #a6a495 2px solid;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 11px;
    BORDER-LEFT: medium none;
    COLOR: #a6a495;
    BORDER-BOTTOM: medium none
}
.searchcaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\corner.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.searchcaption A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: #ed1b24;
    TEXT-DECORATION: none
}
.searchcaption A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: #d3d3d3;
    TEXT-DECORATION: none
}
.companywindow
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    MARGIN-TOP: 3px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 10px;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-COLOR: white
}
.companydetails
{
    BORDER-RIGHT: medium none;
    BORDER-TOP: #a6a495 2px solid;
    FONT-SIZE: 11px;
    BORDER-LEFT: medium none;
    COLOR: black;
    BORDER-BOTTOM: medium none
}
.banner-head
{
    /* BACKGROUND-IMAGE: url(..\Images\banner_strip.png); */
    BACKGROUND-REPEAT: repeat-x;
    HEIGHT: 55px;
	
}

.companycaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\corner.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #ffffff;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.companycaption A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    TEXT-DECORATION: none
}
.companycaption A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: #d3d3d3;
    TEXT-DECORATION: none
}
BODY A
{
    TEXT-DECORATION: none;
}
BODY A:link
{
    TEXT-DECORATION: none;
}
BODY A:visited
{
    TEXT-DECORATION: none;
}
.menu-top
{
    BORDER-RIGHT: white 2px solid;
    BORDER-TOP: white 1px solid;
    PADDING-LEFT: 5px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    MARGIN-BOTTOM: 2px;
    BORDER-LEFT: white 4px solid;
    COLOR: white;
    BORDER-BOTTOM: white 1px solid;
    BACKGROUND-COLOR: white
}
.menu-sub
{
    BORDER-RIGHT: 1px;
    BORDER-TOP: 1px;
    BORDER-LEFT: 1px;
    BORDER-BOTTOM: 1px;
    BACKGROUND-COLOR: #a6a495
}
.companyname
{
    BACKGROUND-POSITION: left top;
    /* BACKGROUND-IMAGE: url(..\Images\logo_name.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.companyheading
{
    FONT-SIZE: 20px;
    FONT-FAMILY: Tahoma
}
.menu-line
{
    BORDER-BOTTOM: #a6a495 4px inset
}
.searchwindow A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 11px;
    COLOR: #a6a495
}
.searchwindow A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 11px;
    COLOR: #ed1b24
}
.mainwindow
{
    BORDER-RIGHT: #a6a495 3px solid;
    BORDER-TOP: #a6a495 3px solid;
    BORDER-LEFT: #a6a495 3px solid;
    BORDER-BOTTOM: #a6a495 3px solid
}
.rightlogo
{
    BACKGROUND-POSITION: left bottom;
    /* BACKGROUND-IMAGE: url(..\images\rightlogo.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.searchresultheading
{
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-COLOR: #a6a495
}

.searchresultheading a:visited
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 13px;
    COLOR: white;
}

.searchresultheading a:link
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 13px;
    COLOR: white;
}

.searchresult
{
    FONT-SIZE: 12px;
    MARGIN: 0px
}
.searchline1
{
    FONT-SIZE: 12px
}
.searchline2
{
    FONT-SIZE: 12px;
    BACKGROUND-COLOR: #f5f5f5
}
.lookupresultheading
{
    FONT-SIZE: 12px;
    COLOR: white;
    BACKGROUND-COLOR: #a6a495
}
.lookupresult
{
    FONT-SIZE: 12px;
    MARGIN: 0px
}
.lookupline1
{
    FONT-SIZE: 12px;
    BACKGROUND-COLOR: white
}
.lookupline2
{
    FONT-SIZE: 12px;
    BACKGROUND-COLOR: #f5f5f5
}
.lookupline3
{
    FONT-SIZE: 12px;
    BACKGROUND-COLOR: white
}
SELECT
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 9pt;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid
}
.calcwindow
{
    BORDER-RIGHT: #a6a495 1px groove;
    BORDER-TOP: #a6a495 1px groove;
    BORDER-LEFT: #a6a495 1px groove;
    BORDER-BOTTOM: #a6a495 1px groove;
    BACKGROUND-COLOR: #a6a495
}
.instructionleft
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: left top;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 14px;
    /* BACKGROUND-IMAGE: url(..\Images\InputBackground.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #a6a495;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: white
}
.companymoto
{
    MARGIN-TOP: 0px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 20px;
    COLOR: #a6a495;
    PADDING-TOP: 0px;
    /* BACKGROUND-: url(..\Images\logo.gif) */
}
.errormessage
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 12px;
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: black;
    BORDER-BOTTOM: #a6a495 1px solid
}
.savemessage
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 12px;
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: black;
    BORDER-BOTTOM: #a6a495 1px solid
}
.lookupwindow
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    FONT-SIZE: 11px;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-COLOR: white
}
.errorcaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: 2px center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 20px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\warning.gif); */
    MARGIN-LEFT: 8px;
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.savecaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: 2px center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 20px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\okay.gif); */
    MARGIN-LEFT: 8px;
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.stepscaption
{
    BORDER-RIGHT: #a6a495 1px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\arrow.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: white;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: #a6a495
}
.newstarterwindow
{
    BORDER-RIGHT: #a6a495 2px solid;
    BORDER-TOP: #a6a495 2px solid;
    FONT-SIZE: 10pt;
    BORDER-LEFT: #a6a495 2px solid;
    BORDER-BOTTOM: #a6a495 2px solid;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: white
}
.newstartercaption
{
    PADDING-RIGHT: 5px;
    PADDING-LEFT: 5px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    MARGIN-BOTTOM: 5px;
    PADDING-BOTTOM: 2px;
    COLOR: white;
    PADDING-TOP: 2px;
    FONT-FAMILY: Arial;
    BACKGROUND-COLOR: #a6a495
}
.stepscaption A:visited
{
    FONT-SIZE: 12px;
    COLOR: white
}
.scrollListSmall
{
    OVERFLOW: auto;
    HEIGHT: 1o0px
}
.scrollList
{
    OVERFLOW: auto;
    HEIGHT: 160px
}
.scrollListLarge
{
    OVERFLOW: auto;
    HEIGHT: 450px
}
.scrollListMedium
{
    OVERFLOW: auto;
    HEIGHT: 250px
}
.blue
{
    BORDER-BOTTOM: #3089cb solid
}
.yellow
{
    BORDER-BOTTOM: #ffd900 solid
}
.red
{
    BORDER-BOTTOM: #ed1b24 solid
}
.green
{
    BORDER-BOTTOM: #19b14c solid
}
.white
{
    BORDER-BOTTOM: white solid
}
.max-4
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(..\Images\red-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-1
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(..\Images\blue-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-2
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(..\Images\green-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.max-3
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(..\Images\yellow-long.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.logo
{
    BACKGROUND-POSITION: center center;
    /* BACKGROUND-IMAGE: url(..\Images\logo.png); */
    BACKGROUND-REPEAT: no-repeat
}
.profile
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 16pt;
    COLOR: #ed1b24
}
.navigate
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 13px
}
.navigate A:Hover
{
    COLOR: #ed1b24;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 13px

}
.navigate A
{
    BORDER-RIGHT: medium none;
    BORDER-TOP: medium none;
    FONT-WEIGHT: bolder;
    FONT-SIZE: larger;
    BORDER-LEFT: medium none;
    COLOR: white;
    BORDER-BOTTOM: medium none
}
.nav-banner
{
    BORDER-RIGHT: #a6a495 1px solid;
    BORDER-TOP: #a6a495 1px solid;
    BORDER-LEFT: #a6a495 1px solid;
    BORDER-BOTTOM: #a6a495 1px solid
}
.nav-heading
{
    BACKGROUND-POSITION: left center;
    /* BACKGROUND-IMAGE: url(..\Questionaire\images\header.gif); */
    BACKGROUND-REPEAT: no-repeat
}
.employeedesc
{
    BORDER-RIGHT: #a6a495 5px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\arrow_desc.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #ed1b24;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.employeedesc A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    TEXT-DECORATION: none
}
.employeedesc A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: #ed1b24;
    TEXT-DECORATION: none
}
.employeeasc
{
    BORDER-RIGHT: #a6a495 5px solid;
    BACKGROUND-POSITION: left center;
    BORDER-TOP: #a6a495 1px solid;
    PADDING-LEFT: 15px;
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    /* BACKGROUND-IMAGE: url(..\Images\arrow_asc.gif); */
    BORDER-LEFT: #a6a495 1px solid;
    COLOR: #ed1b24;
    BORDER-BOTTOM: #a6a495 1px solid;
    BACKGROUND-REPEAT: no-repeat;
    BACKGROUND-COLOR: #a6a495
}
.employeeasc A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: white;
    TEXT-DECORATION: none
}
.employeeasc A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 12px;
    COLOR: #ed1b24;
    TEXT-DECORATION: none
}
.clarity4d
{
    FONT-SIZE: 40pt;
    COLOR: white;
    FONT-FAMILY: Tahoma;
    BACKGROUND-COLOR: white
}
.strapline
{
    FONT-SIZE: small;
    COLOR: #a6a495;
    FONT-FAMILY: Verdana;
    BACKGROUND-COLOR: white
}
.profilehead
{
    FONT-SIZE: x-small;
    COLOR: white;
    BACKGROUND-COLOR: #a6a495
}
.contact
{
    FONT-SIZE: x-small;
    COLOR: #333333;
    BACKGROUND-COLOR: white
}
.profilehead A
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: x-small;
    COLOR: white
}
.profilehead A:hover
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: x-small;
    TEXT-DECORATION: underline
}
.selectedprogress
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 11pt;
    COLOR: dimgray;
    FONT-FAMILY: Arial
}
.Frame1
{
    BORDER-RIGHT: #c5c1c1 1px solid;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 1px solid;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #c5c1c1 1px solid;
    COLOR: #6b6363;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #c5c1c1 1px solid;
    FONT-FAMILY: Arial, Verdana
}
.Frame2
{
    BORDER-RIGHT: #6f8bdf 2px;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 4px double;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #6f8bdf 2px;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #6f8bdf;
    FONT-FAMILY: Arial
}
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.md-60 { font-size: 60px; }
.material-icons.orange { color: #FB8C00; }
.material-icons.black { color: #231f20; }
.material-icons.grey { color: #9d9d9d }
.material-icons.blue { color: #3089cb }
.material-icons.red { color: #ed1b24 }
.material-icons.yellow { color: #ffd900 }
.material-icons.green { color: #19b14c }
.material-icons.purple { color: #6a00e2 }




