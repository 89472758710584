.pd-r-10px{
    padding: 0px 10px 0px 0px;
}

.pd-l-10px{
    padding: 8px 0px 0px 10px;
}

.btn-statusclr{
    padding: 1px 10px !important;
width: 100px;
}

.teammemebertoggle{
    position: absolute;
    right: 30px;
    border: 3px solid #8c8282;
    border-radius: 5px;
    cursor: pointer;
    margin-top: -47px;
  }
  .teammemebertoggleinfo{
    position: relative;
  right: -66%;
  top: 4px;
  border-radius: 5px;
  margin: 5px 20px;
  cursor: pointer;
  }
  .backwardicon{
    position: relative;
  left: 5px;
  }
  .openvalue{
    width: 96%;
    max-width: 96% !important;
  }
  .togglebtn{
    margin-top: 55px !important;
    align-self: start !important;
  }
  .dnone{
    display: none !important;
  }
/* 
  .header_title{
 
    margin-bottom: 13px !important;
    font-size: 1.6rem;
    font-weight: 600;
    color: #1976d2;
    padding-top: 5px;
      } */
      .teammemeberdd{
        min-width: 260px;
        padding-bottom: 10px;
        padding-top: 10px;
    
      }
@media screen and (max-width: 800px) {
  .header_title{     
    margin: 0px !important;
    font-size: 1.3rem !important;
    font-weight: 600;
    color: #1976d2;
    padding-top: 5px;

  }
  .teammemeberdd{
    min-width: 293px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.searchtm{
 margin-top: 10px; 
}
  .searctm{
    width: 245px;
  }
  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5.5px 14px !important;
  }
  .MuiInputBase-input-MuiOutlinedInput-input{
    padding: 4.5px 14px !important;
  }
  .MuiButtonBase-root-MuiIconButton-root{
    padding: 4px !important;
  }
}