.sidebar_bg .MuiPaper-root{
    background-color: #1976d2 !important;
    color:#ffffff;
}
.sidebar_bg .MuiListItemIcon-root{
    color:#ffffff;
}

.css-h4y409-MuiList-root{
    padding-top: 0px !important;
}