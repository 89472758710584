.icn-position{
    display: flex;
    justify-content: center;
    align-items: center;
}

.brd-rad{
    border-radius: 13px !important;
}

.bg-color {
    /* background: #1bc5bd !important; */
    color: white !important;
}
.bg-alert{
background: rgb(246, 78, 96) none repeat scroll 0% 0% !important;
color: white !important;
}