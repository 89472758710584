.survey-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .survey-def {
    border: 1px solid #dddddd !important;
    text-align: left !important;
    padding: 8px !important;
  }

  .survey-header {
    border: 1px solid #dddddd !important;
    text-align: left !important;
    padding: 8px !important;
  }
  
  .answervalue:nth-child(even) {
    background-color: #dddddd !important;
  }

  .questionvalue:nth-child(even) {
    background-color: #dddddd !important;
  }

  .not-completed {
    background: #bfbfbf;
    border-radius: 50px;
    height: 22px;
    width: 22px;
  }

  .completed {
    /* background: #92d050; */
    border-radius: 50px;
    height: 22px;
    width: 22px;
    background-image: url('../../assets/completed-tick.png');
    background-repeat: no-repeat;
  }

  .skipped {
    /* background: #ffa800; */
    height: 22px;
    width: 22px;
    border-radius: 50px;
    background-image: url('../../assets/pending-equal.png');
    background-repeat: no-repeat;
  }

  .text-center {
    text-align: center !important;
  }

  .present {
    /* background: #0094fb; */
    height: 22px;
    width: 22px;
    border-radius: 50px;
    background-image: url('../../assets/skipped-icn.png');
    background-repeat: no-repeat;
  }
  .fs-1{
    font-size: 1.2rem;
    /* font-weight: 500; */
  }

  .fs-1-fw-500{
    font-size: 1.4rem;
    font-weight: 500 !important;
  }
  .outerQuestionvalue{
    border: 2px solid #bfbfbf;
border-radius: 4px;
padding: 15px 21px;
  }

  .questionvalue th{
   background-color: #a5a5a5 !important;
   color: #fff !important;
   border: 2px solid #fff;
   border-bottom: 4px solid #fff;
   text-align: center;
  }

  .questionvalue th label{
  
    color: #fff !important;
    margin: 0px;
   }
 .justifyContentEnd{
  justify-content: end;
  display: grid;
 }

  .answervalue td{
   background-color: #e1e1e1 !important;
   color: #fff !important;
   border: 1px solid #fff;
   text-align: center;
  }
  .answervalue td label{
    margin:0px;
  }
.d-flex{
  display: flex; 
}
.margin-24{
  margin: 25px 11px;

}
.margin-24 label{
  margin-left: 5px;
}
.pr-1{
  padding-right: 20px;
}

  .statusclrcomplete {
    /* background: #92d050 !important; */
    color: #92d050;
    height: 22px;
    width: 22px;
    border-radius: 100px;
    display: inline-block;
    /* border: 1px solid #444643 !important; */
    margin: 0px 5px;
  }
  .statusclrskipped {
    /* background: #ffa800; */
    color: #ffa800;
    height: 22px;
    width: 22px;
    border-radius: 100px;
    display: inline-block;
    /* border: 1px solid #444643 !important; */
    margin: 0px 5px;
    }
    .statusclrpresent {
      /* background: #0094fb; */
      color: #0094fb;
      height: 22px;
      width: 22px;
      border-radius: 100px;
      display: inline-block;
      /* border: 1px solid #444643 !important; */
      margin: 0px 5px;
      }


      .rightquestionstatus{
        height: 91vh;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
        margin-top: 50px !important;
      }

      .scroollauto{
        height: calc(100vh - 50px);
      }
      .initialvalue{
        padding-right: 30px;
        padding-top: 0px;
      }

      .pd-l-5{
        padding: 0px 0px 0px 5px;
      }

      .align-items-center{
        align-items: center;
      }