.loginht {
    margin: 70px 100px;
    text-align: center;
}
.hr-bg-color{
    background-color: #3699ff;
    /* background-color:  #304fff; */
}
.logoimg{
    margin-left: 36%;
    position: relative;
    top: 49px;
  }
.login-header{
    padding: 0px;
    height: 100vh;
    overflow-y: auto;
}
.outervalue{
    padding: 10rem 4rem;
}
.outervalueright{
    /* padding: 2rem 12rem; */
}
.text{
    /*font-size: 1.6rem;*/
	font-size: 1.24rem;
    color: #ffffff;
  }
  .gr-image{
    padding: 0rem;
    border: 1px solid #e9e9e9;
    height: 30%;
    text-align: center;
    justify-content: end;
  }
  .bottom-text{
    position: absolute;
    bottom: 30px;
    color: #fff;
    font-size: 1.1rem;
  }
  .bottom-text span {
      padding-right: 4rem;
  }
  .pb-10{
    padding-bottom: 1.1rem;
}
.mt-10{
    margin-top: 10px;
}
.submit-cont p{
    display: inline;
    position: relative;
    top: -10px;
    float: left;

}
.submit-cont p a{
    text-decoration: underline !important;
color: #3089cb;
}
.float-right{
    float: right;
}
.logo{
    text-align: center;
margin-bottom: 45px;
margin-right: 31px;
    /* line-height: 1;
text-align: center;
margin-bottom: 6rem;
margin-top: 5rem; */
}
.title{
    margin: 0;
font-size: 4rem;
font-weight: 600;
color: #3699ff;
}
.title-sub{
    font-size: 2rem;
margin: 0;
color: #5e5d5d;
padding-left: 11px;
}
.loginusernamecontainer{
    margin-right: auto;
    margin-left: auto;
    width: 420px;
    padding-bottom: 135px;
}

.pt-23{
    padding-top: 10px;
}
.footerpart{
    color: #fff;
    font-size: 1.1rem;
    position: absolute;
    bottom: 15px;
    width: 40%;
  }
.footvalue{
    display: flex;
justify-content: space-around;
}
.leftside{
    height: 100vh;
background: #3699ff;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}
@media screen and (max-width: 800px) {
.loginusernamecontainer{
    margin-right: auto;
    margin-left: auto;
    width: 320px;
    padding-bottom: 135px;
}
}
@media screen and (max-width: 500px) and (min-width: 300px) {
    .loginusernamecontainer{
        margin-right: auto;
        margin-left: auto;
        width: 280px;
        padding-bottom: 135px;
    }
    .logo img{
        width: 280px;
    }
    }
/* .logoicon{
    padding: 3rem 6rem;
}
.App-logo , .App-logo-icon{
    height: 3rem;
}
.rightsidelogo{
    display: inline-flex;
    margin: 3% 15%;
}
.part1{
    padding: 2rem 3rem;
    border: 2px solid #A9A9AE;
    border-radius: 3rem;
    margin-left: 5rem;
}
.align-right{
    text-align: right;
}
.content{
    color: #3F4254;
    margin-top: 2rem;
}
.clr-3F4254{
    color: #3F4254;
}
.arrowdown{
    font-size: 2rem;
position: absolute;
margin-left: 1rem;
border: 1px solid #a8a3a3;
border-radius: 5rem;
margin-top: 0.7rem;
color: #a8a3a3;
}
.border-1{
    padding: 2rem 3rem;
}
.border-2{
    padding: 1.3rem 3rem;
}
.pb-10{
    padding-bottom: 1.1rem;
}
.pb-2{
    padding-bottom: 2rem;
}
.width-1{
    width: 20rem;
}
.logoon{
    margin: 0;
font-size: 2rem;
font-weight: 400;
padding-bottom: 0.8rem;
}
.part2{
    border-bottom: 2px solid #A9A9AE;
    padding-bottom: 0.5rem;
    
}
.m-0{
    margin: 0;
} */

.guide-link{
    color : #0857a4;
    text-decoration: underline;
    font-size: 1.5rem;
}

.guide-link:hover{
    color : blue;
    text-decoration: underline;
    font-size: 1.5rem;   
}

.youtube-icn{
    width: 25px;
    padding-top: 6px;
    padding-right: 6px;
}

.align-items-center{
    align-items: center;
}

.pointer {
    cursor: pointer !important;
}

@media screen and (min-width: 800px) {
    .loginusernamecontainer{
        margin-right: auto;
        margin-left: auto;
        width: 320px;
        padding-bottom: 135px;
    }
    }
    @media screen and (max-width: 500px) and (min-width: 300px) {
        .loginusernamecontainer{
            margin-right: auto;
            margin-left: auto;
            width: 280px;
            padding-bottom: 135px;
        }
        .logo img{
            width: 280px;
        }
        }
.txt-start{
	text-align : start
}

.lg-h2-clr{
    color : #ffffff
}

.p-rl-25{
    padding : 0px 25px
}

.justify-content-center{
    justify-content: center;
}

.m-t--110px{
    margin-top: -110px;
}

.m-t--35px{
    margin-top: -35px;
}

.m-t-70px{
    margin-top: 70px;
}