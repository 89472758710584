.pd-l-8px{
    padding: 0px 0px 0px 8px;
}
.m-t-10{
    margin-top: 20px;
}

  @media screen and (max-width: 800px) {
    .pd-r-10px {
        padding: 0px 10px 5px 0px;
      }
      .text-right{
        text-align: left;
      }
}