.message-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.text-center {
    text-align: center;
}
.alert-msg{
    font-size: 2rem;
font-weight: 500;
}