.bg-header .css-19z1ozs-MuiPaper-root-MuiAppBar-root{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.back-color{
    background: #fff !important;
    color: black !important;
}
.bg-header .css-1191obr-MuiPaper-root-MuiAppBar-root{
    background: #fff !important;
    color: black !important;
    z-index: 0!important;  
    margin-left: 0px;
}

@media screen and (max-width: 800px) {
.headermb{
    z-index: 0!important;
    width: calc(100% - 0px)!important;
}
.css-1191obr-MuiPaper-root-MuiAppBar-root{
    z-index: 0 !;
}
}
@media only screen and (max-width: 1000px) and (min-width: 800px){
    .headermb{
        z-index: 0!important;
        width: calc(100% - 0px)!important;
        margin-left: 0px;
    }
}

.css-168noda{
	background-color: #ffffff !important;
}

.css-vwd6ro{
	background-color: #ffffff !important;
}

.css-pio6kb{
	color : #000000 !important
}

.css-1l1167e{
	color : #000000 !important
}