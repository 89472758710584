.menu-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: #f1f1f1;
  }
  
  .menu-list-item a {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
  }
  
  .menu-list-item a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .menu-list-item a:hover:not(.active) {
    background-color: #555;
    color: white;
  }