.pd-r-10px{
    padding: 0px 10px 0px 0px;
}
/* .header_title{
  margin: 0;
  margin-bottom: 0px;
margin-bottom: 13px;
font-size: 1.5rem;
font-weight: 600;
color: #1976d2;
padding-top: 10px;
  } */
  .toggleicon{
    position: relative;
    top: 3px;
    right: 0px;
  }

  .teamtoggle{
    position: absolute;
    right: 30px;
    border: 3px solid #8c8282;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
  }
  .teamtoggleinfo{
    position: relative;
    left: 3px;
    top: 4px;
    border-radius: 5px;
    margin: 5px 20px;
    cursor: pointer;
  }
.backwardicon{
  position: relative;
left: 5px;
}
.openvalue{
  width: 96%;
  max-width: 96% !important;
}
.togglebtn{
  margin-top: 55px !important;
  align-self: start !important;
}
.m-t-5{
  margin-top: 10px !important;
}

@media screen and (min-width: 800px) {
  .header_title{
    margin-bottom: 0px !important;
  }
 .searchwidth{
  width: 245px !important;
 }
}

.disp-none {
  display: none;
}