.css-f3jnds-MuiDataGrid-columnHeaders{
    background-color: #888d93;
    color: #fff;
    font-weight: 700;
    font-size: 1.1rem;
}

.MuiDataGrid-columnHeaders{
    min-height: 40px !important;
    max-height: 45px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight{
    display: none !important;
}

.css-4bz76g-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
    right: -12px;
    display: none !important;
}
.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight{
    display: none;
}
.MuiDataGrid-columnSeparator{
    display: none !important;
}
.MuiDataGrid-row{
    min-height: 40px !important;
    max-height: 40px !important;
}
.MuiDataGrid-cell {
    min-height: 40px !important;
    max-height: 40px !important;
}
.MuiDataGrid-cell:last-child{
    justify-content: center !important;
    
}
.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
    height: auto !important;
  }

  .css-1w5m2wr-MuiDataGrid-virtualScroller{
    overflow-y: auto !important;
  }