.panel-icn-size{
    font-size: 4.5rem !important;
}

.message-icn-size{
    font-size: 4.5rem !important;
}

.panel-icn-center{
    margin: 0 auto;
}