.icn-custom{
    background: #1976d2 !important;
    color: #fff !important;
    border-radius: 5px !important;
}
.icn-toggle-custom{
    width: 24px;
    background: #1976d2 !important;
    color: #fff !important;
    border-radius: 5px !important;
}