
.icn-color {
    color : #fff;
}

.brd-rad{
    border-radius: 13px !important;
}

.panel-alignment {
    display: flex;
    align-items: center;
}
.ds-box-shadow{
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important
}
.ds-text-content{
    font-size: 1.2rem;
    font-weight: 500;
  }