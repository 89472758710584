
.MainCaption
{
    BORDER-RIGHT: #6f8bdf;
    BORDER-TOP: #6f8bdf;
    BORDER-LEFT: #6f8bdf;
    COLOR: #6b6363;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Frame1
{
    BORDER-RIGHT: #c5c1c1 1px solid;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 1px solid;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #c5c1c1 1px solid;
    COLOR: #6b6363;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #c5c1c1 1px solid;
    FONT-FAMILY: 'Arial Rounded MT Bold', Verdana
}
.Frame2
{
    BORDER-RIGHT: #6f8bdf 2px;
    PADDING-RIGHT: 2px;
    BORDER-TOP: #c5c1c1 4px double;
    PADDING-LEFT: 2px;
    PADDING-BOTTOM: 2px;
    BORDER-LEFT: #6f8bdf 2px;
    PADDING-TOP: 2px;
    BORDER-BOTTOM: #6f8bdf;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Head1
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 25px;
    COLOR: #6b6363;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Bullet
{
    FONT-SIZE: 10pt;
    COLOR: black;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Head2
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 14pt;
    COLOR: #6b6363;
    BORDER-BOTTOM: #c5c1c1 1px solid;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Head3
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 17pt;
    COLOR: #6b6363;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.Statement1
{
    FONT-SIZE: small;
    FONT-FAMILY: 'Arial Rounded MT Bold', Verdana
}
.Frame3
{
    BORDER-RIGHT: #c5c1c1 8px double;
    BORDER-TOP: #c5c1c1 8px double;
    BORDER-LEFT: #c5c1c1 8px double;
    BORDER-BOTTOM: #c5c1c1 8px double;
    FONT-FAMILY: 'Arial Rounded MT Bold'
}
.ChartSplitTop
{
    BORDER-RIGHT: gray 1px solid;
    BORDER-BOTTOM: gray 1px solid
}
.ChartSplitBottom
{
    BORDER-TOP: gray 1px solid;
    BORDER-LEFT: gray 1px solid
}
.DNA-table
{
    FONT-SIZE: small;
    FONT-WEIGHT: 100;
    FONT-FAMILY: 'Arial Rounded', Verdana
}
.DNA-head
{
    FONT-SIZE: small;
    FONT-WEIGHT: bold;
    FONT-FAMILY: 'Arial Rounded MT Bold', Verdana
}
.DNA-score
{
    FONT-SIZE: 10px;
    text-align: right;
    FONT-FAMILY: 'Arial Rounded', Verdana
}
.DNA-front-page
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 0px -70px;
    width:780px;
    height: 1400px;
    BACKGROUND-IMAGE: url(./images/DNA Swirl.png);
    BACKGROUND-REPEAT: no-repeat
}
.DNA-front-bottom-page
{
	POSITION:RELATIVE;
    BACKGROUND-POSITION: 1200px 500px;
    width:500px;
    height: 1000px;
    BACKGROUND-IMAGE: url(./images/DNA Swirl.png);
    BACKGROUND-REPEAT: no-repeat
}
.DNA-topbottom
{
    BACKGROUND-IMAGE: url(./images/Element Line Chart.png);
    BACKGROUND-REPEAT: no-repeat;
    background-position: left top;
}
.TableL
{
    BORDER-LEFT: black 2px solid
}
.TableFill
{
    BACKGROUND-COLOR: black 
}
.ChartL
{
    BORDER-LEFT: gray 1px solid
}
.ChartT
{
    BORDER-TOP: gray 1px solid
}
.ChartB
{
    BORDER-BOTTOM: gray 1px dotted
}
.ChartLT
{
    BORDER-LEFT: gray 1px solid;
    BORDER-TOP: gray 1px solid
}
.TableTask
{
    FONT-SIZE: 14px;
    color: black;
    FONT-FAMILY: 'Arial Rounded MT Bold';
    BACKGROUND-IMAGE: url(./images/Task vs Relationship1.png);
    BACKGROUND-REPEAT: no-repeat;
    background-position: left top;
}
.TableRelation
{
    FONT-SIZE: 14px;
    color: black;
    FONT-FAMILY: 'Arial Rounded MT Bold';
    BACKGROUND-IMAGE: url(./images/Task vs Relationship2.png);
    BACKGROUND-REPEAT: no-repeat;
    background-position: left top;
}
.FontLarge
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 26px;
    color: White;
    FONT-FAMILY: 'Arial Rounded MT Bold';    
}
.FontLargeBlack
{
    FONT-WEIGHT: bolder;
    FONT-SIZE: 17px;
    color: black;
    FONT-FAMILY: 'Arial Rounded MT Bold';    
}
.FontSmall
{
    FONT-WEIGHT: lighter;
    FONT-SIZE: 13px;
    FONT-FAMILY: 'Arial Rounded MT ';    
}
.FontMedium
{
    FONT-WEIGHT: normal;
    FONT-SIZE: 15px;
    FONT-FAMILY: 'Arial Rounded MT ';    
}
.grey-box
{
    BACKGROUND-COLOR: #F8F8F8;
}