body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mar-0{
  margin: 0px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-normalize {
  text-transform: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.line-height-normal {
  line-height: normal !important;
}

.font-size-12px{
  font-size: 12px;
}

.dialogCloseClr{
  color:#0000 !important
}

.ipt-size{
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.text-right{
  text-align: right;
}

.pd-r-10px {
  padding: 0px 10px 0px 0px;
}

.nav-highlight {
  text-decoration: underline;
  color: #157ce2;
  cursor: pointer;
}

.pd-r-15px{
  padding: 0px 15px 0px 0px;
}

.pd-l-10px{
  padding: 0px 0px 0px 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-color{
  color: #aba8a8;
}

.inpt-height{
  height: 30px !important;
}

.rm-brdr{
  border-radius: 0px !important;
}

/* .MuiOutlinedInput-root{
  border-radius: 0px !important;
  padding: 0px 10px !important;
  height: 30px !important;
} */

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 3px 0px !important;
} */

/* .MuiInputLabel-root{
  top: -12px !important
} */

.MuiOutlinedInput-root{
  border-radius: 0px !important;
}