.pd-l-2px{
    padding: 0px 0px 0px 2px;
}

.css-19r6kue-MuiContainer-root {
    padding-left:  0px !important;
    padding-right: 0px !important;
}
.bg-primary{
    color: #1976d2;
}
.bg-gray{
    color:#5e5a5a !important;
}
.w-100{
    width: 100%;
}

/* .scroollauto{
    overflow: auto;
height: calc(100vh - 49px);
} */